import React, { useState } from 'react';
import { Row, Col, Form, Button, Input, notification } from 'antd';
import axios from "axios";
import { SERVICE_URL } from "../../utils/constants";
import Logo from '../../assets/images/logo.jpeg';
import { useNavigate } from "react-router-dom";
import './login.css';

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const [isInProcess, setIsInProcess] = useState<boolean>(false)
    const letLogin = async (values: any) => {
        setIsInProcess(true)
        try {
            if (values?.email && values?.password) {
                let res: any = await axios.post(`${SERVICE_URL}login/index.php`, values, { withCredentials: true })
                if (res?.data?.status) {
                    localStorage.setItem('wa.username', res.data.username);
                    localStorage.setItem('wa.role', res.data.role);
                } else if (res?.data?.status === false) {
                    notification['error']({
                        message: 'Failed',
                        description: 'Invalid Credentials!',
                        duration: 5,
                        style: { backgroundColor: '#ff9494' },
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                        key: 'all'
                    });
                } else throw new Error
            } else throw new Error
            navigate("/dashboard");
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Something went wrong. Try after some time',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
        }
        setIsInProcess(false)
    }

    return (
        <div className="loginpg">
            <Row>
                <Col span={24}>
                    <div className="web-authform-right">
                        <div className="web-authform-container">
                            <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={Logo} alt='logo' style={{ width: '45%' }} />
                                {/*<h1>Welcome</h1>*/}
                            </div>
                            <br />
                            <div className='loginpg-header'>
                                <h2>Login</h2>
                            </div>
                            <Form disabled={isInProcess} layout="vertical" autoComplete="off" onFinish={letLogin}>
                                <Form.Item label={<label>Username</label>} name="email" rules={[{ required: true, message: "Please enter your email/mobile number!" }]} >
                                    <Input type="text" />
                                </Form.Item>
                                <Form.Item label={<label>Password</label>} name="password" rules={[{ required: true, message: "Please enter the password!" }]} >
                                    <Input.Password />
                                </Form.Item>
                                <Button className="web-primary-btn" htmlType="submit">
                                    Login
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default LoginPage;