import { GeneralStore } from "./generalstore";

export type RootStore = {
    generalStore: GeneralStore
}

const rootStore = {
    generalStore: new GeneralStore()
};

export default rootStore;