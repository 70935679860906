import React, { useEffect, useState, useRef } from 'react';
import { Table, Spin, Row, Col, Space, notification, InputRef, Button, Input, Modal } from 'antd';
import { PageLayout } from "../../layouts/page/page";
import { SERVICE_URL } from "../../utils/constants";
import axios from "axios";
import { SearchOutlined, ReloadOutlined, DownloadOutlined } from '@ant-design/icons';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

const InvoiceListPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [listData, setListData] = useState<any>([])
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<any>([]);

    const getInvoices = async () => {
        try {
            await axios.get(`${SERVICE_URL}Tax Invoice/getInvoice.php`, { withCredentials: true }).then((_c: any) => {
                if (_c.data) {
                    setListData(_c.data)
                    setIsLoading(false)
                } else throw new Error()
            }).catch(err => {
                console.error(err)
                notification['error']({
                    message: 'Failed',
                    description: 'Something went wrong',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            })
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Mobile no is not valid',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
        }
    }
    useEffect(() => {
        getInvoices()
    }, [])
    const showItems = async (id: any) => {
        try {
            setIsLoading(true)
            let _post: any = {
                invoiceid: id
            }
            await axios.post(`${SERVICE_URL}Tax Invoice/getInvoiceItems.php`, _post, { withCredentials: true }).then((_c: any) => {
                if (_c.data) {
                    setItems(_c.data)
                    setOpen(true)
                } else throw new Error()
            }).catch(err => {
                console.error(err)
                notification['error']({
                    message: 'Failed',
                    description: 'Something went wrong',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            })
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Mobile no is not valid',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
        }
    }
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const _columns: ColumnsType<any> = [
        {
            title: 'Invoice No',
            width: 80,
            dataIndex: 'invoice_no',
            key: 'invoice_no',
            fixed: 'left',
            ...getColumnSearchProps('invoice_no'),
        },
        {
            title: 'Bill To Name',
            width: 100,
            dataIndex: 'billto_name',
            key: 'billto_name'
        },
        {
            title: 'Bill To GSTN',
            width: 100,
            dataIndex: 'billto_gstn',
            key: 'billto_gstn'
        },
        {
            title: 'Bill To Phone',
            width: 100,
            dataIndex: 'billto_phone',
            key: 'billto_phone'
        },

        {
            title: 'Ship To Name',
            width: 100,
            dataIndex: 'shipto_name',
            key: 'shipto_name'
        },
        {
            title: 'Ship To GSTN',
            width: 100,
            dataIndex: 'shipto_gstn',
            key: 'shipto_gstn'
        },
        {
            title: 'Ship To Phone',
            width: 100,
            dataIndex: 'shipto_phone',
            key: 'shipto_phone'
        },
        {
            title: 'Created Date',
            width: 70,
            dataIndex: 'created_date',
            key: 'created_date',
            render: (_, record) => (
                <>{moment(record.created_date).format('DD-MM-YYYY h:m:s')}</>
            )
        },
        /*{
            title: 'Created Name',
            width: 90,
            dataIndex: 'created_name',
            key: 'created_name'
        },*/
        {
            title: 'Sub Total(₹)',
            width: 50,
            dataIndex: 'Total_beforetax',
            key: 'Total_beforetax'
        },
        {
            title: 'Total(₹)',
            width: 50,
            dataIndex: 'Total_aftertax',
            key: 'Total_aftertax'
        },
        {
            title: 'Invoice',
            width: 70,
            dataIndex: 'filepath',
            key: 'filepath',
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <a href={SERVICE_URL + record.filepath} target='_blank'><DownloadOutlined /></a>
                </Space>
            )
        },
        {
            title: 'Action',
            width: 70,
            dataIndex: 'visible',
            key: 'visible',
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => showItems(record.invoice_id)}>Show Items</a>
                </Space>
            )
        }
    ]
    const _item_columns: ColumnsType<any> = [
        {
            title: 'Description Goods',
            width: 80,
            dataIndex: 'desc_goods',
            key: 'desc_goods'
        },
        {
            title: 'HSN Code',
            width: 100,
            dataIndex: 'hsncode',
            key: 'hsncode'
        },
        {
            title: 'Quantity',
            width: 100,
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Rate(₹)',
            width: 100,
            dataIndex: 'rate',
            key: 'rate'
        },
        {
            title: 'Amount(₹)',
            width: 100,
            dataIndex: 'amount',
            key: 'amount'
        }
    ]
    let letsReload = async () => {
        window.location.reload();
    }


    return (
        <PageLayout title="Invoice">
            <Spin spinning={isLoading} >
                <div className='app-container'>
                    <Row>
                        <Col span={22}>
                            <h2>Invoice List</h2>
                        </Col>
                        <Col span={2}>
                            <button onClick={letsReload} style={{
                                background: '#fff',
                                cursor: 'pointer',
                                color: "var(--webPrimary-dark)"
                            }} type='button' className="web-primary-btn">Reload <ReloadOutlined /></button>
                        </Col>
                    </Row>
                    <div className='vertical-pd-2'>
                        <Table columns={_columns} dataSource={listData} scroll={{ x: 1700, y: 400 }} />
                    </div>
                </div>
            </Spin>

            <Modal title="Item Details" centered open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width={'90vw'}>
                <Table columns={_item_columns} dataSource={items} scroll={{ x: 1700, y: 400 }} />
            </Modal>
        </PageLayout>
    )
}

export default InvoiceListPage;