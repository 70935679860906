import React, { useState, useEffect } from 'react';
import { PageLayout } from "../../layouts/page/page";
import { Form, Row, Col, Select, Input, Button, Divider, notification, Spin, DatePicker, InputNumber, Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import { SERVICE_URL } from "../../utils/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './billing.css';
import moment from 'moment';

const BillingPage: React.FC = () => {
    const navigate = useNavigate()
    if (!localStorage.getItem('wa.username') || !localStorage.getItem('wa.role') || localStorage.getItem('wa.role') !== "2") {
        localStorage.removeItem('wa.role');
        localStorage.removeItem('wa.username');
        navigate("/")
    }
    const [form] = Form.useForm();
    const [company, setCompany] = useState<any>([])
    const [allCompany, setAllCompany] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [taxType, setTaxType] = useState<any>("")
    const onChange = (values: any) => {
        const fields = form.getFieldsValue()
        let _stot: number = 0;
        let _goods: any[] = [];
        fields.goods.map((_m: any) => {
            let _gds: any = {}
            _gds.desc_goods = _m.desc_goods || null
            _gds.hsn_code = _m.hsn_code || null
            _gds.quantity = _m.quantity || 1
            _gds.rate = _m.rate || 1
            _gds.amount = (_gds.quantity * _gds.rate)
            _stot += _gds.amount
            _goods.push(_gds)
        })
        let _cgst: number = ((_stot / 100) * fields.cgst || 0);
        let _sgst: number = ((_stot / 100) * fields.sgst || 0);
        let _igst: number = ((_stot / 100) * fields.igst || 0);
        
          let _tot: number = taxType === "GST"?(_stot + _cgst + _sgst):(_stot + _igst);
        
       
        form.setFieldsValue({
            goods: _goods,
            subtotal: _stot,
            total: _tot
        });
    }
    const [isFormProgress, setIsFormProgress] = useState<boolean>(false)
    const getCompany = async () => {
        try {
            await axios.get(`${SERVICE_URL}company/index.php`, { withCredentials: true }).then((_c: any) => {
                let _cd: any = []
                if (_c.data) {
                    _c.data.map((_v: any) => {
                        _cd.push({ label: _v.name, value: _v.cid })
                    })
                    setCompany(_cd)
                    setAllCompany(_c.data)
                    setIsLoading(false)
                } else throw new Error()
            }).catch(err => {
                console.error(err)
                notification['error']({
                    message: 'Failed',
                    description: 'Something went wrong',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            })
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Mobile no is not valid',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
        }
    }
    useEffect(() => {
        getCompany()
    }, [])
    const getCompanyAddress = async (v: string) => {
        try {
            let _add = await allCompany.find((_m: any) => _m.cid === v)
            form.setFieldsValue({
                company_address: _add.address,
                gstin: _add.gstin
            });
        } catch (err) {
            console.log(err)
        }
    }
    const isValidMobile = async (mobile: any) => {
        return mobile.match('[0-9]{10}');
    }
    const onFinish = async (values: any) => {
        setIsFormProgress(true)
        try {
            if (values && await isValidMobile(values.billto_phone) && await isValidMobile(values.shipto_phone) && values.goods?.length > 0) {
                let _post: any = {
                    "company_id": values.company_id,
                    "invoice_no": values.invoice_no,
                    "billto_name": values.billto_name,
                    "billto_address": values.billto_address,
                    "billto_gstn": values.billto_gstn,
                    "billto_phone": values.billto_phone,
                    "shipto_name": values.shipto_name,
                    "shipto_address": values.shipto_address,
                    "shipto_gstn": values.shipto_gstn,
                    "shipto_phone": values.shipto_phone,
                    "dispatched_through": values.dispatched_through,
                    "destination": values.destination,
                    "cgst": values.cgst || null,
                    "sgst": values.sgst ||null,
                    "goods": values.goods,
                    "invoice_date": moment(values.invoice_date).format('DD/MM/YYYY'),
                    "tax_type": taxType,
                    "igst":values.igst|| null

                }
                await axios.post(`${SERVICE_URL}Tax Invoice/index.php`, _post, { withCredentials: true }).then((_d: any) => {
                    if (_d.data?.status) {
                        notification['success']({
                            message: 'Success',
                            description: _d.data?.Message || 'Record saved Successfully',
                            duration: 5,
                            style: { backgroundColor: '#85bf79' },
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                            key: 'all'
                        });
                        form.resetFields();
                    } else {
                        notification['error']({
                            message: 'Failed',
                            description: _d.data?.Message || 'Try one more time',
                            duration: 5,
                            style: { backgroundColor: '#ff9494' },
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                            key: 'all'
                        });
                    }
                }).catch((err: any) => {
                    console.error(err)
                    notification['error']({
                        message: 'Failed',
                        description: 'Something went wrong in the server',
                        duration: 5,
                        style: { backgroundColor: '#ff9494' },
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                        key: 'all'
                    });
                })
            } else {
                notification['error']({
                    message: 'Failed',
                    description: 'Form validation failed, Check once',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            }
            setIsFormProgress(false)
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Something went wrong. Try after some time',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
            setIsFormProgress(false)
        }
    }
    const pasteShipdata = (e: any) => {
        const fields = form.getFieldsValue()
        if (e) {
            form.setFieldsValue({
                shipto_name: fields.billto_name,
                shipto_address: fields.billto_address,
                shipto_gstn: fields.billto_gstn,
                shipto_phone: fields.billto_phone
            });
        } else {
            form.setFieldsValue({
                shipto_name: null,
                shipto_address: null,
                shipto_gstn: null,
                shipto_phone: null
            });
        }
    }

    return (
        <PageLayout title="Invoice">
            <Spin spinning={isLoading} >
                <Form initialValues={{ remember: true }} form={form} disabled={isFormProgress} onFinish={onFinish} className='billingpg-frm' layout={'horizontal'} labelCol={{ span: 24 }} labelAlign="left" wrapperCol={{ span: 24 }} >
                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className='app-container'>
                                <div className='vertical-pd-2'>
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}><h2>Company Details</h2></Col>
                                    </Row>
                                    <Divider />
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Select Company</label>} name="company_id" rules={[{ required: true, message: 'Please enter Select the company' }]} >
                                                <Select showSearch style={{ display: 'block' }} options={company} onChange={(_v: any) => { getCompanyAddress(_v) }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>GSTIN</label>} name="gstin" rules={[{ required: true, message: 'Please enter the GSTIN / UIN' }]} >
                                                <Input readOnly />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='start'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label >Company Address</label>} name="company_address" rules={[{ required: true }]} >
                                                <Input.TextArea rows={2} style={{ paddingTop: ".5rem" }} readOnly />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Invoice No</label>} name="invoice_no" rules={[{ required: true, message: 'Please enter the Invoice No' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Invoice Date</label>} name="invoice_date" rules={[{ required: true, message: 'Please enter the Invoice Date' }]} >
                                                <DatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className='app-container'>
                                <div className='vertical-pd-2'>
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}><h2>Billing To</h2></Col>
                                    </Row>
                                    <Divider />
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label>Company Name</label>} name="billto_name" rules={[{ required: true, message: 'Please enter the Company Name' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label >Address</label>} name="billto_address" rules={[{ required: true, message: 'Please enter the Company Address' }]} >
                                                <Input.TextArea rows={2} style={{ paddingTop: ".5rem" }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label>GSTIN</label>} name="billto_gstn" rules={[{ required: true, message: 'Please enter the GSTIN' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label>Phone Number</label>} name="billto_phone" rules={[{ required: true, message: 'Please enter the Phone Number' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className='app-container'>
                                <div className='vertical-pd-2'>
                                    <Row gutter={[12, 12]}>
                                        <Col span={12}><h2>Shipping To</h2></Col>
                                        <Col span={12} style={{ textAlign: 'end' }}>
                                            <Checkbox onChange={(e) => pasteShipdata(e.target.checked)} style={{
                                                background: '#fff',
                                                cursor: 'pointer',
                                                color: "var(--webPrimary-dark)"
                                            }}>same as billing address</Checkbox>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label>Company Name</label>} name="shipto_name" rules={[{ required: true, message: 'Please enter the Company Name' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label >Address</label>} name="shipto_address" rules={[{ required: true, message: 'Please enter the Company Address' }]} >
                                                <Input.TextArea rows={2} style={{ paddingTop: ".5rem" }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label>GSTIN</label>} name="shipto_gstn" rules={[{ required: true, message: 'Please enter the GSTIN' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label>Phone Number</label>} name="shipto_phone" rules={[{ required: true, message: 'Please enter the Phone Number' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className='app-container'>
                                <div className='vertical-pd-2'>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Dispatched Through</label>} name="dispatched_through" rules={[{ required: false, message: 'Please enter the Dispatched Through' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Destination</label>} name="destination" rules={[{ required: false, message: 'Please enter the User Name' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                    <Col xs={10} sm={8} md={6} lg={6} xl={6} xxl={6}>
                                            <Form.Item label={<label>Tax Type</label>} name="tax_type" rules={[{ required: true, message: 'Please select the Tax type' }]} >
                                                <Select showSearch style={{ display: 'block' }} options={[{value:"GST", label:"GST"},{value:"IGST", label:"IGST" }]} onChange={(_v: any) => { setTaxType(_v); console.log(_v) }} />
                                            </Form.Item>
                                        </Col>
                      
                                        {taxType && taxType ==="GST" && <><Col xs={10} sm={8} md={6} lg={6} xl={6} xxl={6}>
                                            <Form.Item label={<label>CGST(%)</label>} name="cgst" rules={[{ required: true, message: 'Please enter the CGST' }]} >
                                                <Input onChange={() => { onChange(''); }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={10} sm={8} md={6} lg={6} xl={6} xxl={6}>
                                            <Form.Item label={<label>SGST(%)</label>} name="sgst" rules={[{ required: true, message: 'Please enter the SGST' }]} >
                                                <Input onChange={() => { onChange(''); }} />
                                            </Form.Item>
                                        </Col></>}

                                        {taxType && taxType ==="IGST" && <><Col xs={10} sm={8} md={6} lg={6} xl={6} xxl={6}>
                                            <Form.Item label={<label>IGST(%)</label>} name="igst" rules={[{ required: true, message: 'Please enter the IGST' }]} >
                                                <Input onChange={() => { onChange(''); }} />
                                            </Form.Item>
                                        </Col></>}
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className='app-container'>
                                <div className='vertical-pd-2'>
                                    <Form.List name="goods">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Row gutter={[4, 4]} key={key}>
                                                        <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={7}>
                                                            <Form.Item {...restField} name={[name, 'desc_goods']} rules={[{ required: true, message: 'Missing Item name' }]} >
                                                                <Input placeholder="Item Name" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                                                            <Form.Item {...restField} name={[name, 'hsn_code']} rules={[{ required: true, message: 'Missing HSN Code' }]} >
                                                                <Input placeholder="HSN Code" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={12} lg={12} xl={3} xxl={3}>
                                                            <Form.Item {...restField} name={[name, 'quantity']} rules={[{ required: true, message: 'Missing Quantity' }]} >
                                                                <InputNumber placeholder="Quantity" style={{ width: '100%' }} min={1} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={11} sm={11} md={12} lg={12} xl={3} xxl={3}>
                                                            <Form.Item {...restField} name={[name, 'rate']} rules={[{ required: true, message: 'Missing Rate' }]} >
                                                                <InputNumber placeholder="Rate(₹)" onChange={(e: any) => { onChange(e) }} style={{ width: '100%' }} min={1} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={23} lg={23} xl={4} xxl={4}>
                                                            <Form.Item {...restField} name={[name, 'amount']} rules={[{ required: true, message: 'Missing Amount' }]} >
                                                                <InputNumber readOnly min={1} placeholder="Amount(₹)" style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ textAlign: 'center' }}>
                                                            <MinusCircleOutlined onClick={() => { remove(name); onChange(''); }} />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Add Item
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                    <Row gutter={[4, 4]} justify={'end'}>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ textAlign: 'end' }}>
                                            <Form.Item label={<label>Sub Total(₹)</label>} name="subtotal" >
                                                <Input readOnly style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]} justify={'end'}>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ textAlign: 'end' }}>
                                            <Form.Item label={<label>Total(₹)</label>} name="total" >
                                                <Input readOnly style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <Row>
                                        <Col span={24}>
                                            <Button htmlType="submit" className="web-primary-btn" style={{ margin: 'unset' }}>
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </PageLayout>
    )
}

export default BillingPage;