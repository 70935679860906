import React, { useState, useEffect } from 'react';
import { PageLayout } from "../../layouts/page/page";
import { Form, Row, Col, Select, Input, Button, Divider, notification, Spin } from 'antd';
import { SERVICE_URL } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './user.css';

const UserPage: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    if (!localStorage.getItem('wa.username') || !localStorage.getItem('wa.role') || localStorage.getItem('wa.role') !== "1") {
        localStorage.removeItem('wa.role');
        localStorage.removeItem('wa.username');
        navigate("/")
    }
    const [isFormProgress, setIsFormProgress] = useState<boolean>(false)
    const [company, setCompany] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const getCompany = async () => {
        try {
            await axios.get(`${SERVICE_URL}company/index.php`, { withCredentials: true }).then((_c: any) => {
                let _cd: any = []
                if (_c.data) {
                    _c.data.map((_v: any) => {
                        _cd.push({ label: _v.name, value: _v.cid })
                    })
                    setCompany(_cd)
                    setIsLoading(false)
                } else throw new Error()
            }).catch(err => {
                console.error(err)
                notification['error']({
                    message: 'Failed',
                    description: 'Something went wrong',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            })
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Mobile no is not valid',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
        }
    }
    useEffect(() => {
        getCompany()
    }, [])
    const isValidMobile = async (mobile: any) => {
        return mobile.match('[0-9]{10}');
    }
    const onFinish = async (values: any) => {
        try {
            setIsFormProgress(true)
            if (values && await isValidMobile(values.mobile)) {
                let res: any = await axios.post(`${SERVICE_URL}signup/index.php`, values, { withCredentials: true })
                if (res?.data?.status) {
                    notification['success']({
                        message: 'Success',
                        description: 'User Created Successfully',
                        duration: 5,
                        style: { backgroundColor: '#85bf79' },
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                        key: 'all'
                    });
                    form.resetFields();
                } else if (res?.data?.status === false) {
                    notification['error']({
                        message: 'Failed',
                        description: res?.data?.Message || 'Something went wrong',
                        duration: 5,
                        style: { backgroundColor: '#ff9494' },
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                        key: 'all'
                    });
                } else throw new Error
            } else {
                notification['error']({
                    message: 'Failed',
                    description: 'Mobile no is not valid',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            }
            setIsFormProgress(false)
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Something went wrong. Try after some time',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
            setIsFormProgress(false)
        }
    }

    return (
        <PageLayout title="Users">
            <Spin spinning={isLoading} >
                <div className='app-container'>
                    <h2>Create New User</h2>
                    <div className='vertical-pd-2'>
                        <Form autoComplete={'off'} form={form} disabled={isFormProgress} onFinish={onFinish} layout="vertical" labelCol={{ span: 24 }} labelAlign="left" wrapperCol={{ span: 24 }} >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                    <Form.Item label={<label>Select Company Name</label>} name="company_id" rules={[{ required: true, message: 'Please enter Select the company' }]} >
                                        <Select showSearch style={{ display: 'block' }} options={company} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                    <Form.Item label={<label>Name</label>} name="name" rules={[{ required: true, message: 'Please enter the User Name' }]} >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                    <Form.Item label={<label>Email Address</label>} name="email" rules={[{ required: false, message: 'Please enter the User email' }]} >
                                        <Input type='email' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                    <Form.Item label={<label>Mobile Number</label>} name="mobile" rules={[{ required: true, message: 'Please enter the mobile number' }]} >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                    <Form.Item name="password" label={<label>Password</label>} rules={[{ min: 8, required: true, message: "Please input your password. Minimum 8 characters!" }]}>
                                        <Input.Password max={8} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                    <Form.Item name="confirmpassword" label={<label>Confirm Password</label>} dependencies={["password"]}
                                        rules={[
                                            { required: true, message: "Please input your password!" },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("password") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error("The two passwords that you entered do not match!"));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={24}>
                                    <Button htmlType="submit" className="web-primary-btn" style={{ margin: 'unset' }}>
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Spin>
        </PageLayout>
    )
}

export default UserPage;