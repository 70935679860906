import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./screens/login/login";
import DashboardPage from "./screens/dashboard/dashboard";
import UserPage from "./screens/user/user";
import ProtectedRoutes from "./layouts/ProtectedRoutes";
import BillingPage from "./screens/billing/billing";
import ChallanPage from "./screens/challan/challan";
import CompanyPage from "./screens/company/company";
import UserListPage from "./screens/userlist/userlist";
import CompanyListPage from "./screens/companylist/companylist";
import InvoiceListPage from "./screens/invoicelist/invoicelist";
import "./App.css";

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route element={<ProtectedRoutes />}>
                        <Route path="/dashboard" element={<DashboardPage />} />
                        <Route path="/user" element={<UserPage />} />
                        <Route path="/billing" element={<BillingPage />} />
                        <Route path="/challan" element={<ChallanPage />} />
                        <Route path="/company" element={<CompanyPage />} />
                        <Route path="/userlist" element={<UserListPage />} />
                        <Route path="/companylist" element={<CompanyListPage />} />
                        <Route path="/invoicelist" element={<InvoiceListPage />} />
                    </Route>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
