import { makeObservable, observable } from "mobx";

export class GeneralStore {
    /***********************************MENU */
    isNavOpen: boolean = false;
    isMenuOpen: boolean = false;
    selectedMenu: string | null | undefined;

    constructor() {
        makeObservable(this, {
            isNavOpen: observable,
            isMenuOpen: observable,
            selectedMenu: observable
        });
    }
    /***********************************************************************ACTIONS */
    toggleMenuVisibility = () => {
        console.log("inside toggleMenuVisibility");
        this.isMenuOpen = !this.isMenuOpen;
    };
    setSelectedMenuItem(v?: string) {
        this.selectedMenu = v;
    };
    showResponsive = () => {
        this.isNavOpen = !this.isNavOpen;
    };
}
