import React, { useState, useEffect } from 'react';
import { PageLayout } from "../../layouts/page/page";
import { Form, Row, Col, Select, Input, Button, Divider, notification, Spin, DatePicker } from 'antd';
import { SERVICE_URL } from "../../utils/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../billing/billing.css';
import moment from 'moment';

const ChallanPage: React.FC = () => {
    const navigate = useNavigate()
    if (!localStorage.getItem('wa.username') || !localStorage.getItem('wa.role') || localStorage.getItem('wa.role') !== "2") {
        localStorage.removeItem('wa.role');
        localStorage.removeItem('wa.username');
        navigate("/")
    }
    const [form] = Form.useForm();
    const [company, setCompany] = useState<any>([])
    const [allCompany, setAllCompany] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isFormProgress, setIsFormProgress] = useState<boolean>(false)
    const getCompany = async () => {
        try {
            await axios.get(`${SERVICE_URL}company/index.php`, { withCredentials: true }).then((_c: any) => {
                let _cd: any = []
                if (_c.data) {
                    _c.data.map((_v: any) => {
                        _cd.push({ label: _v.name, value: _v.cid })
                    })
                    setCompany(_cd)
                    setAllCompany(_c.data)
                    setIsLoading(false)
                } else throw new Error()
            }).catch(err => {
                console.error(err)
                notification['error']({
                    message: 'Failed',
                    description: 'Something went wrong',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            })
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Mobile no is not valid',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
        }
    }
    useEffect(() => {
        getCompany()
    }, [])
    const getCompanyAddress = async (v: string) => {
        try {
            let _add = await allCompany.find((_m: any) => _m.cid === v)
            form.setFieldsValue({
                company_address: _add.address,
                gstin: _add.gstin
            });
        } catch (err) {
            console.log(err)
        }
    }
    const onFinish = async (values: any) => {
        setIsFormProgress(true)
        try {
            if (values) {
                values.date = moment(values.date).format('DD/MM/YYYY')
                values.issue_date = moment(values.issue_date).format('DD/MM/YYYY')
                console.log(values)
                await axios.post(`${SERVICE_URL}/jobwork/index.php`, values, { withCredentials: true }).then((_d: any) => {
                    if (_d.data?.status) {
                        notification['success']({
                            message: 'Success',
                            description: _d.data?.Message || 'Record saved Successfully',
                            duration: 5,
                            style: { backgroundColor: '#85bf79' },
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                            key: 'all'
                        });
                        form.resetFields();
                    } else {
                        notification['error']({
                            message: 'Failed',
                            description: _d.data?.Message || 'Try one more time',
                            duration: 5,
                            style: { backgroundColor: '#ff9494' },
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                            key: 'all'
                        });
                    }
                }).catch((err: any) => {
                    console.error(err)
                    notification['error']({
                        message: 'Failed',
                        description: 'Something went wrong in the server',
                        duration: 5,
                        style: { backgroundColor: '#ff9494' },
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                        key: 'all'
                    });
                })
            } else {
                notification['error']({
                    message: 'Failed',
                    description: 'Form validation failed, Check once',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            }
            setIsFormProgress(false)
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Something went wrong. Try after some time',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
            setIsFormProgress(false)
        }
    }

    return (
        <PageLayout title="Job Work">
            <Spin spinning={isLoading} >
                <Form initialValues={{ remember: true }} form={form} disabled={isFormProgress} onFinish={onFinish} className='billingpg-frm' layout={'horizontal'} labelCol={{ span: 24 }} labelAlign="left" wrapperCol={{ span: 24 }} >
                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className='app-container'>
                                <div className='vertical-pd-2'>
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}><h2>Challan Details</h2></Col>
                                    </Row>
                                    <Divider />
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Select Company</label>} name="company_id" rules={[{ required: true, message: 'Please enter Select the company' }]} >
                                                <Select showSearch style={{ display: 'block' }} options={company} onChange={(_v: any) => { getCompanyAddress(_v) }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>GSTIN</label>} name="gstin" rules={[{ required: true, message: 'Please enter the GSTIN / UIN' }]} >
                                                <Input readOnly />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='start'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <Form.Item label={<label >Company Address</label>} name="company_address" rules={[{ required: true }]} >
                                                <Input.TextArea rows={2} style={{ paddingTop: ".5rem" }} readOnly />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Description of goods</label>} name="description" rules={[{ required: true, message: 'Please enter the Description of goods' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Quantity</label>} name="quatity" rules={[{ required: true, message: 'Please enter the Quantity' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Tariff Classification</label>} name="tariff" rules={[{ required: true, message: 'Please enter the Tariff Classification' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Estimateed value of inputs / partially processed inputs</label>} name="estimate" rules={[{ required: true, message: 'Please enter the value' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Date and Time of Issue</label>} name="issue_date" rules={[{ required: true, message: 'Please enter the Date and Time of Issue' }]} >
                                                <DatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Name of processing manufacturing required to be done</label>} name="manufacturing" rules={[{ required: true, message: 'Please enter the value' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Factory / Place of processing / manufacturing : Name</label>} name="factory_name" rules={[{ required: true, message: 'Please enter the Invoice No' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Factory / Place of processing / manufacturing: Address</label>} name="factory_address" rules={[{ required: true, message: 'Please enter the value' }]} >
                                                <Input.TextArea rows={2} style={{ paddingTop: ".5rem" }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Factory / Place of processing / manufacturing : GSTIN</label>} name="factory_gstin" rules={[{ required: true, message: 'Please enter the Invoice No' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Expected duration of processing / manufacturing</label>} name="duration" rules={[{ required: true, message: 'Please enter the value' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Transport Name</label>} name="transport_name" rules={[{ required: true, message: 'Please enter the value' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Transport No</label>} name="transport_no" rules={[{ required: true, message: 'Please enter the value' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>From JJ No: </label>} name="jj_no" rules={[{ required: true, message: 'Please enter the value' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>place</label>} name="place" rules={[{ required: true, message: 'Please enter the value' }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[4, 4]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item label={<label>Date</label>} name="date" rules={[{ required: true, message: 'Please enter the value' }]} >
                                                <DatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <Row>
                                        <Col span={24}>
                                            <Button htmlType="submit" className="web-primary-btn" style={{ margin: 'unset' }}>
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </PageLayout>
    )
}

export default ChallanPage;