import React, { useEffect, useState, useRef } from 'react';
import { Table, Spin, Row, Col, Space, notification, InputRef, Button, Input } from 'antd';
import { PageLayout } from "../../layouts/page/page";
import { SERVICE_URL } from "../../utils/constants";
import axios from "axios";
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';

const CompanyListPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [listData, setListData] = useState<any>([])
    const getCompany = async () => {
        try {
            await axios.get(`${SERVICE_URL}company/index.php`, { withCredentials: true }).then((_c: any) => {
                if (_c.data) {
                    setListData(_c.data)
                    setIsLoading(false)
                } else throw new Error()
            }).catch(err => {
                console.error(err)
                notification['error']({
                    message: 'Failed',
                    description: 'Something went wrong',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            })
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Mobile no is not valid',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
        }
    }
    useEffect(() => {
        getCompany()
    }, [])
    const doVisibility = async (_post: any) => {
        try {
            setIsLoading(true)
            _post = {
                id: _post.id,
                status: _post.status === '1' ? 0 : 1
            }
            await axios.post(`${SERVICE_URL}/signup/updatestatus.php`, _post, { withCredentials: true }).then((_c: any) => {
                if (_c.data) {
                    getCompany()
                } else throw new Error()
            }).catch(err => {
                console.error(err)
                notification['error']({
                    message: 'Failed',
                    description: 'Something went wrong',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            })
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Mobile no is not valid',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
        }
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const _columns: ColumnsType<any> = [
        {
            title: 'Company Name',
            width: 100,
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Image',
            width: 100,
            dataIndex: 'image',
            key: 'image'
        },
        {
            title: 'URL',
            width: 100,
            dataIndex: 'url',
            key: 'url'
        },
        {
            title: 'Address',
            width: 100,
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: 'Delivery Terms',
            width: 100,
            dataIndex: 'delivery_terms',
            key: 'delivery_terms'
        },
        {
            title: 'GSTIN',
            width: 100,
            dataIndex: 'delivery_terms',
            key: 'delivery_terms'
        },
        /*{
            title: 'Createdby',
            width: 100,
            dataIndex: 'createdby',
            key: 'createdby'
        },
        {
            title: 'Action',
            width: 100,
            dataIndex: 'visible',
            key: 'visible',
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => doVisibility({ id: record.cid, status: record.visible })}>{record.visible === "0" ? 'Lets Deactivate' : 'Make Visible'}</a>
                </Space>
            )
        } */
    ]
    let letsReload = async () => {
        window.location.reload();
    }


    return (
        <PageLayout title="Company">
            <Spin spinning={isLoading} >
                <div className='app-container'>
                    <Row>
                        <Col span={22}>
                            <h2>Company List </h2>
                        </Col>
                        <Col span={2}>
                            <button onClick={letsReload} style={{
                                background: '#fff',
                                cursor: 'pointer',
                                color: "var(--webPrimary-dark)"
                            }} type='button' className="web-primary-btn">Reload <ReloadOutlined /></button>
                        </Col>
                    </Row>
                    <div className='vertical-pd-2'>
                        <Table columns={_columns} dataSource={listData} scroll={{ x: 1700, y: 400 }} />
                    </div>
                </div>
            </Spin>
        </PageLayout>
    )
}

export default CompanyListPage;