import { useContext, createContext, ReactNode } from "react";
import { useLocalObservable } from 'mobx-react';
import rootStore, { RootStore } from "./stores";

const storeContext = createContext<RootStore | null>(null);

export const StoreProvider = ({ children }: { children: ReactNode }) => {
    const store = useLocalObservable(() => rootStore);
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
}

export const useStore = () => {
    const store = useContext(storeContext);
    if (!store) {
      // this is especially useful in TypeScript so you don't need to be checking for null all the time
      throw new Error('useStore must be used within a StoreProvider.');
    }
    return store;
  }
