import React, { useEffect, useState } from "react";
import { Menu, notification } from 'antd';
import { Observer } from 'mobx-react';
import axios from "axios";
import { SERVICE_URL } from "../../utils/constants";
import {
    MenuOutlined, AppstoreOutlined, ContainerOutlined,
    SnippetsOutlined, LogoutOutlined, ShopOutlined, IdcardOutlined,
    UserAddOutlined, AuditOutlined, ReconciliationOutlined, UsergroupAddOutlined
} from "@ant-design/icons";
import { useStore } from "../../use-stores";
import { useNavigate } from "react-router-dom";
import './sidemenu.css';

function getItem(label: string, key: string | number, icon?: any, children?: any, type?: any) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

export const SideMenu: React.FC = () => {
    console.log('inside re render of sidemenu');
    const { generalStore } = useStore();
    const navigate = useNavigate();
    const [items, setMenuItems] = useState([getItem('Menu', 'menu', <AppstoreOutlined />)]);
    if (localStorage.getItem('wa.role')) {
        console.log('inside menu construction');
        if (items.length !== 7 && localStorage.getItem('wa.role') === '1') {
            setMenuItems(items => [
                getItem('Menu', 'menu', <MenuOutlined />),
                getItem('Dashboard', 'dashboard', <AppstoreOutlined />),
                getItem('Company', 'company', <ShopOutlined />),
                getItem('Users', 'user', <UserAddOutlined />),
                getItem('View Companies', 'report_company', <IdcardOutlined />),
                getItem('View Users', 'report_user', <UsergroupAddOutlined />),
                /*getItem('Repost', 'report', <ReconciliationOutlined />, [
                    getItem('Company', 'report_company'),
                    getItem('User', 'report_user')
                ]),*/
                getItem('Logout', 'logout', <LogoutOutlined />)
            ]);
        } else if (items.length !== 6 && localStorage.getItem('wa.role') === '2') {
            setMenuItems(items => [
                getItem('Menu', 'menu', <MenuOutlined />),
                getItem('Dashboard', 'dashboard', <AppstoreOutlined />),
                getItem('Invoice', 'billing', <SnippetsOutlined />),
                getItem('Job Work', 'challan', <AuditOutlined />),
                getItem('View Invoice', 'report_invoice', <ContainerOutlined />),
                /*getItem('Repost', 'report', <ReconciliationOutlined />, [
                    getItem('Invoice', 'report_invoice'),
                ]),*/
                getItem('Logout', 'logout', <LogoutOutlined />),
            ]);
        }
    } else navigate('/');
    const onMenuItemSelection = (menuprops: any): void => {
        const { key } = menuprops;
        console.log(key)
        if (key === 'menu') {
            generalStore.toggleMenuVisibility();
        } else if (key === 'logout') {
            localStorage.removeItem('wa.role');
            localStorage.removeItem('wa.username');
            generalStore.setSelectedMenuItem();
            const letLogin = async (values: any) => {
               
                try {
                   
                        let res: any = await axios.post(`${SERVICE_URL}logout/index.php`, values, { withCredentials: true })
                        if (res?.data?.status) {
                            //localStorage.setItem('wa.username', res.data.username);
                            //localStorage.setItem('wa.role', res.data.role);
                            navigate('/');
                        } else if (res?.data?.status === false) {
                            notification['error']({
                                message: 'Failed',
                                description: 'Invalid Credentials!',
                                duration: 5,
                                style: { backgroundColor: '#ff9494' },
                                onClick: () => {
                                    console.log('Notification Clicked!');
                                },
                                key: 'all'
                            });
                        } else throw new Error
                    }  catch (err) {
                    console.error(err)
                    notification['error']({
                        message: 'Failed',
                        description: 'Something went wrong. Try after some time',
                        duration: 5,
                        style: { backgroundColor: '#ff9494' },
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                        key: 'all'
                    });
                }
                
            }


            letLogin({name:"logout"});
           
        } else {
            if (key === generalStore.selectedMenu) return;
            generalStore.setSelectedMenuItem(key);
            if (key === 'dashboard') {
                console.log('selected menu item is dashboard')
                navigate('/dashboard');
            } else if (key === 'user') {
                console.log('selected menu item is user')
                navigate('/user');
            } else if (key === 'billing') {
                console.log('selected menu item is billing')
                navigate('/billing');
            } else if (key === 'challan') {
                console.log('selected menu item is challan')
                navigate('/challan');
            } else if (key === 'company') {
                console.log('selected menu item is company')
                navigate('/company');
            } else if (key === 'report_user') {
                console.log('selected menu item is report_user')
                navigate('/userlist');
            } else if (key === 'report_company') {
                console.log('selected menu item is report_company')
                navigate('/companylist');
            } else if (key === 'report_invoice') {
                console.log('selected menu item is report_invoice')
                navigate('/invoicelist');
            }
        }
    }
    

    return <Observer>{() => {
        return (
            <Menu className="Admin-menu" style={{ minHeight: "92.5vh", height: "100%", background: "var(--Adminprimary)" }}
                defaultSelectedKeys={['menu', 'dashboard']}
                mode="inline"
                theme="dark"
                selectedKeys={[generalStore.selectedMenu || "dashboard"]}
                inlineCollapsed={!generalStore.isMenuOpen}
                items={items}
                onClick={onMenuItemSelection}
            />
        )
    }}</Observer>
}