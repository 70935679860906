import React, { useState } from 'react';
import { PageLayout } from "../../layouts/page/page";
import { Form, Row, Col, Select, Input, Button, Divider, notification, Spin, Upload, message, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { SERVICE_URL } from "../../utils/constants";
import axios from "axios";

const props: UploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info: any) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

const CompanyPage: React.FC = () => {
    const [form] = Form.useForm();
    const [isFormProgress, setIsFormProgress] = useState<boolean>(false)
    const onFinish = async (values: any) => {
        try {
            setIsFormProgress(true)
            if (values) {
                let res: any = await axios.post(`${SERVICE_URL}company/addCompany.php`, values, { withCredentials: true })
                if (res?.data?.status) {
                    notification['success']({
                        message: 'Success',
                        description: 'User Created Successfully',
                        duration: 5,
                        style: { backgroundColor: '#85bf79' },
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                        key: 'all'
                    });
                    form.resetFields();
                } else if (res?.data?.status === false) {
                    notification['error']({
                        message: 'Failed',
                        description: res?.data?.Message || 'Something went wrong',
                        duration: 5,
                        style: { backgroundColor: '#ff9494' },
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                        key: 'all'
                    });
                } else throw new Error
            } else {
                notification['error']({
                    message: 'Failed',
                    description: 'Mobile no is not valid',
                    duration: 5,
                    style: { backgroundColor: '#ff9494' },
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    key: 'all'
                });
            }
            setIsFormProgress(false)
        } catch (err) {
            console.error(err)
            notification['error']({
                message: 'Failed',
                description: 'Something went wrong. Try after some time',
                duration: 5,
                style: { backgroundColor: '#ff9494' },
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                key: 'all'
            });
            setIsFormProgress(false)
        }
    }

    return (
        <PageLayout title="Company">
            <div className='app-container'>
                <h2>Create New Company</h2>
                <div className='vertical-pd-2'>
                    <Form disabled={isFormProgress} onFinish={onFinish} form={form} autoComplete={'off'} layout="vertical" labelCol={{ span: 24 }} labelAlign="left" wrapperCol={{ span: 24 }} >
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <Form.Item label={<label>Company Name</label>} name="name" rules={[{ required: true, message: 'Please enter the company name' }]} >
                                    <Input />
                                </Form.Item>
                            </Col>
                            {/*<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item label={<label>Select Image</label>} name="image" rules={[{ required: true, message: 'Please Select The Image' }]} >
                                    <Upload {...props}>
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>*/}
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item label={<label>Company URL</label>} name="url" rules={[{ required: true, message: 'Please enter the URL' }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item label={<label>Address</label>} name="address" rules={[{ required: true, message: 'Please enter the Address' }]} >
                                    <Input.TextArea rows={2} style={{ paddingTop: ".5rem" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item label={<label>Delivery Terms</label>} name="delivery_terms" rules={[{ required: true, message: 'Please enter the Delivery Terms' }]} >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item label={<label>GSTIN</label>} name="gstin" rules={[{ required: true, message: 'Please enter the GSTIN' }]} >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item name="password" label={<label>Password</label>} rules={[{ min: 8, required: true, message: "Please input your password. Minimum 8 characters!" }]}>
                                    <Input.Password max={8} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item name="confirmpassword" label={<label>Confirm Password</label>} dependencies={["password"]}
                                    rules={[
                                        { required: true, message: "Please input your password!" },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error("The two passwords that you entered do not match!"));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <Button htmlType="submit" className="web-primary-btn" style={{ margin: 'unset' }}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </PageLayout>
    )
}

export default CompanyPage;