import React from 'react';
import { PageLayout } from "../../layouts/page/page";

const DashboardPage: React.FC = () => {
    return (
        <PageLayout title="Dashboard">
            <>Welcome</>
        </PageLayout>
    )
}

export default DashboardPage;