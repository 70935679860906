import { Header } from "antd/lib/layout/layout"
import { Observer } from "mobx-react-lite"
import { useStore } from "../../use-stores";
import { DownOutlined } from "@ant-design/icons";
import Avatar from "src/assets/images/avatar.png";
import Logo from '../../assets/images/logo.jpeg';
import './header.css';

export const HeaderLayout: React.FC<{ title: string }> = ({ title }) => {
    const { generalStore } = useStore();

    return <Observer>
        {() => {
            return <Header className="Admin-header" style={{ backgroundColor: 'rgb(255 255 255)' }}>
                <img className="logo" src={Logo} width={generalStore.isMenuOpen ? "136px" : '100px'} />
                <span style={{ paddingLeft: generalStore.isMenuOpen ? "220px" : "145px" }} className="Admin-header-page-header">{title}</span>
                <div className="Admin-header-profile">
                    <span className="Admin-header-profile-img">
                        <img src={Avatar} alt="avatar" />
                    </span>
                    <span className="flex-align-center" style={{ color: "var(--Adminprimary)", paddingLeft: '5px' }}>
                        {localStorage.getItem('wa.username') ? localStorage.getItem('wa.username') : 'Admin'}
                        <a onClick={e => e.preventDefault()}>
                            <DownOutlined className="ml-2" style={{ fontSize: "12px", color: "white" }} />
                        </a>
                    </span>
                </div>
            </Header>
        }}
    </Observer>
}