import { Content, Footer } from "antd/lib/layout/layout"
import { Observer } from "mobx-react-lite"
import { Layout } from 'antd';
import { useStore } from "../../use-stores";
import Sider from "antd/lib/layout/Sider";
import { SideMenu } from "../sidemenu/sidemenu";
import { HeaderLayout } from "../header/header";
import './page.css';

export const PageLayout: React.FC<{ title: string, children: React.ReactNode }> = ({ title, children }) => {
    const { generalStore } = useStore();
    return <Observer>{() => {
        return (
            <div>
                <Layout className="Admin-page">
                    <HeaderLayout title={title} />
                    <Layout hasSider={true}>
                        <Sider collapsed={!generalStore.isMenuOpen} defaultCollapsed={true}><SideMenu /></Sider>
                        <Content>
                            <div style={{ padding: "2%" }}>
                                <>{children}</>
                            </div>
                        </Content>
                    </Layout>
                    <Footer style={{ bottom: "0", textAlign: "center" }}>© 2022 Company. All rights reserved</Footer>
                </Layout>
            </div>
        )
    }}</Observer>
}